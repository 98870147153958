import React from "react";
import TankContentCard from "./ContentCards/TankContentCard";
import description from "../constants/description.json";
import SeaHorse from "../assets/img/seahorse.png";

import AngelFish from "../assets/img/angelfish.png";

const data = [
  {
    id: 1,
    images: [
      { img: SeaHorse, caption: "Seahorse" },
      { img: AngelFish, caption: "Angel Fish" },
    ],
  },
];

const componentData = {
  title: "Saltwater",
  description: description.saltwater,
};
const Saltwater = () => {
  return <TankContentCard {...componentData} data={data} />;
};

export default Saltwater;
