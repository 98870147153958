import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2.5),
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: 20,
    minHeight: 300,
  },
  gridItem: {
    textAlign: "center",
  },
}));

const TankContentCard = ({ title, description, data }) => {
  const classes = useStyles();

  return (
    <>
      <div className="content-container">
        <Grid justify="center" className={classes.grid} container spacing={0}>
          <Grid item className={classes.gridItem} xs={12}>
            <h1 className="content-title"> {title} </h1>
          </Grid>
          {data.map((data) => {
            const { id, images } = data;
            return (
              <Grid key={id} item className={classes.gridItem} xs={12}>
                {images ? (
                  <Slide arrows={false} indicators={false} easing="ease">
                    {images.map((imgObj, index) => {
                      return (
                        <div key={index} style={styles.imageContainer}>
                          <div style={styles.imageContainer}>
                            <img
                              style={styles.image}
                              src={imgObj.img}
                              alt={imgObj.caption}
                            />
                            <div style={styles.imgCaption}>
                              {imgObj.caption}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slide>
                ) : (
                  <>
                    {/* <Paper elevation={3} className={classes.paper}>
                      <Icon fontSize="large" color={iconColor} />
                      <h4 className="card-heading">{heading}</h4>
                      <p>{descriptionHeading}</p>
                      <br />
                      <p>{description}</p>
                    </Paper> */}
                  </>
                )}
              </Grid>
            );
          })}
          <Grid item className={classes.gridItem} xs={12}>
            <h4 className="content-subtitle">{description}</h4>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const styles = {
  image: {
    width: "85%",
    height: "85%",
    objectFit: "cover",
    borderRadius: 40,
  },
  imgCaption: {
    marginTop: 20,
    fontFamily: "Roboto",
    color: "#3C4858",
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 400,
  },
};
export default TankContentCard;
