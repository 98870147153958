import React from "react";
import TankContentCard from "./ContentCards/TankContentCard";
import description from "../constants/description.json";
import Cichlid from "../assets/img/cichlid.png";

const data = [
  {
    id: 1,
    images: [{ img: Cichlid, caption: "Cichlid" }],
  },
];

const componentData = {
  title: "Cichlids",
  description: description.cichlids,
};
const Cichlids = () => {
  return <TankContentCard {...componentData} data={data} />;
};

export default Cichlids;
