import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2.5),
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: 20,
    minHeight: 300,
  },
  gridItem: {
    textAlign: "center",
  },
}));

const ContentCard = ({ title, description, data }) => {
  const classes = useStyles();

  return (
    <>
      <div className="content-container">
        <Grid justify="center" className={classes.grid} container spacing={2}>
          <Grid item className={classes.gridItem} xs={12}>
            <h1 className="content-title"> {title} </h1>
            <h4 className="content-subtitle">{description}</h4>
          </Grid>
          {data.map((data) => {
            const {
              id,
              Icon,
              iconColor,
              heading,
              descriptionHeading,
              description,
            } = data;
            return (
              <Grid key={id} item className={classes.gridItem} xs={12} md={4}>
                <Paper elevation={3} className={classes.paper}>
                  <Icon fontSize="large" color={iconColor} />
                  <h4 className="card-heading">{heading}</h4>
                  <p>{descriptionHeading}</p>
                  <br />
                  <p>{description}</p>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );
};

export default ContentCard;
