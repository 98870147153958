import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import MessageIcon from "@material-ui/icons/Message";
import Button from "@material-ui/core/Button";

// for netlify contact form submission from handle submit
const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  radioButtonsGroup: {
    textAlign: "start",
  },
  radioButtons: {
    margin: 20,
  },
  paper: {
    padding: theme.spacing(2.5),
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: 20,
    minHeight: 220,
  },
  gridItem: {
    textAlign: "center",
  },
  icon: {
    marginRight: 10,
  },
  form: {},
}));

const ContactContentCard = ({ title, description, contactData }) => {
  const classes = useStyles();
  const [contactPerson, setcontactPerson] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
    contactType: "",
    preferredContact: "",
  });

  const [formErrors, setFormErrors] = useState({
    nameError: "",
    phoneNumberError: "",
    emailError: "",
    messageError: "",
    contactTypeError: "",
    preferredContact: "",
  });

  const [isFormSubmitted, setisFormSubmitted] = useState(false);

  const {
    CardIcon,
    iconColor,
    heading,
    PhoneIcon,
    phoneNumber,
    EmailIcon,
    email,
  } = contactData;

  const handleChange = (event) => {
    // handle all generic inputs using event object
    const name = event.target.name;
    const value = event.target.value;

    setcontactPerson({ ...contactPerson, [name]: value });
  };

  const isNameValid = () => {
    if (!contactPerson.name) {
      setFormErrors({ ...formErrors, nameError: "Name is Requried." });
      return false;
    }
    setFormErrors({ ...formErrors, nameError: "" });
    return true;
  };
  const isPhoneNumberValid = () => {
    if (!contactPerson.phoneNumber) {
      setFormErrors({
        ...formErrors,
        phoneNumberError: "Phone Number is Requried.",
      });
      return false;
    }
    setFormErrors({ ...formErrors, phoneNumberError: "" });
    return true;
  };
  const isEmailValid = () => {
    if (!contactPerson.email) {
      setFormErrors({ ...formErrors, emailError: "Email is Required" });
      return false;
    }
    setFormErrors({ ...formErrors, emailError: "" });
    return true;
  };
  const isMessageValid = () => {
    if (!contactPerson.message) {
      setFormErrors({
        ...formErrors,
        messageError: "A Message is Required.",
      });
      return false;
    }
    setFormErrors({ ...formErrors, messageError: "" });
    return true;
  };

  const isFormValid = () => {
    if (
      isNameValid() &&
      isEmailValid() &&
      isPhoneNumberValid() &&
      isMessageValid()
    ) {
      setFormErrors({
        nameError: "",
        phoneNumberError: "",
        emailError: "",
        messageError: "",
        contactTypeError: "",
        preferredContact: "",
      });
      return true;
    } else {
      return false;
    }
  };

  const formSucessMessageTimeout = () => {
    setTimeout(() => {
      setisFormSubmitted(false);
    }, 10000);
  };
  const handleSubmit = (e) => {
    if (isFormValid()) {
      // for netlify contact form submission
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...contactPerson }),
      })
        .then(() => console.log("form sent"))
        .catch((error) => console.log(error));

      setisFormSubmitted(true);
      formSucessMessageTimeout();
      setcontactPerson({
        name: "",
        email: "",
        phoneNumber: "",
        message: "",
        contactType: "",
        preferredContact: "",
      });
    }
    e.preventDefault();
  };

  return (
    <>
      <div className="content-container">
        <Grid justify="center" className={classes.grid} container spacing={2}>
          <Grid item className={classes.gridItem} xs={12}>
            <h1 className="content-title"> {title} </h1>
            <h4 className="content-subtitle">{description}</h4>
          </Grid>

          <Grid item className={classes.gridItem} xs={12} md={6}>
            <Paper elevation={3} className={classes.paper}>
              <CardIcon fontSize="large" color={iconColor} />
              <h4 className="card-heading">{heading}</h4>
              <div style={styles.contactInfoContainer}>
                <PhoneIcon
                  className={classes.icon}
                  fontSize="default"
                  color="secondary"
                />
                <a href={"tel:+1" + phoneNumber}>{phoneNumber}</a>
              </div>
              <div style={styles.contactInfoContainer}>
                <EmailIcon
                  className={classes.icon}
                  fontSize="default"
                  color="secondary"
                />
                <a href={"mailto:" + email}>{email}</a>
              </div>
            </Paper>
          </Grid>

          <Grid item className={classes.gridItem} xs={12} md={6}>
            <Paper elevation={3} className={classes.paper}>
              <MessageIcon fontSize="large" color={iconColor} />
              <h4 className="card-heading">
                Contact Us by Filling the Form Below
              </h4>
              <form
                name="contact"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                <input type="hidden" name="form-name" value="contact" />
                <FormControl
                  fullWidth
                  className={classes.root}
                  variant="outlined"
                >
                  <TextField
                    value={contactPerson.name}
                    id="name"
                    error={formErrors.nameError ? true : false}
                    helperText={formErrors.nameError}
                    label="Name"
                    name="name"
                    required
                    variant="outlined"
                    onChange={handleChange}
                  />
                  <TextField
                    required
                    value={contactPerson.phoneNumber}
                    name="phoneNumber"
                    id="person-phone-number"
                    error={formErrors.phoneNumberError ? true : false}
                    helperText={formErrors.phoneNumberError}
                    label="Phone Number"
                    variant="outlined"
                    onChange={handleChange}
                  />
                  <TextField
                    required
                    value={contactPerson.email}
                    name="email"
                    id="person-email"
                    error={formErrors.emailError ? true : false}
                    helperText={formErrors.emailError}
                    label="Email"
                    variant="outlined"
                    onChange={handleChange}
                  />
                  <p>
                    For services quotes please specify aquarium type,
                    dimensions/size (roughly), as well as any other relevant
                    infromation regarding your aquarium.
                  </p>
                  <TextField
                    id="message"
                    value={contactPerson.message}
                    placeholder="Tell us a little bit about your inquiry. What do you need assistance with? We will get back to you as soon as possible."
                    error={formErrors.messageError ? true : false}
                    helperText={formErrors.messageError}
                    name="message"
                    label="Message"
                    multiline
                    rows={8}
                    variant="outlined"
                    onChange={handleChange}
                  />
                </FormControl>
                <div className={classes.radioButtonsGroup}>
                  <FormControl className={classes.radioButtons}>
                    <FormLabel style={styles.formLabel}>
                      I am Looking to:
                    </FormLabel>
                    <RadioGroup
                      aria-label="Contact Type"
                      name="contactType"
                      value={contactPerson.contactType}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="Install a New Aquarium"
                        control={<Radio />}
                        label="Install a New Aquarium"
                      />
                      <FormControlLabel
                        value="Service an existing Aquarium"
                        control={<Radio />}
                        label="Service an Exsisting Aquarium "
                      />
                      <FormControlLabel
                        value="Get Information/Quote"
                        control={<Radio />}
                        label="Get Information/Quote"
                      />
                      <FormControlLabel
                        value="Other"
                        control={<Radio />}
                        label="Other (provide details above)"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl
                    className={classes.radioButtons}
                    component="fieldset"
                  >
                    <FormLabel style={styles.formLabel}>
                      Preferred Contact Method:
                    </FormLabel>
                    <RadioGroup
                      aria-label="preferred contact"
                      name="preferredContact"
                      value={contactPerson.preferredContact}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="phone"
                        control={<Radio />}
                        label="Phone"
                      />
                      <FormControlLabel
                        value="email"
                        control={<Radio />}
                        label="Email"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="field">
                  <div data-netlify-recaptcha="true"></div>
                </div>
                <div>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{ margin: 10, width: "200px" }}
                  >
                    Submit
                  </Button>
                </div>
              </form>
              {isFormSubmitted ? (
                <h4 style={styles.formSubmission}>
                  Your message has been sent! I will get back to you as soon as
                  possible.
                </h4>
              ) : null}
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const styles = {
  contactInfoContainer: {
    display: "flex",
    justifyContent: "center",
    margin: 10,
    wordBreak: "break-all",
  },
  formSubmission: {
    color: "#00b330",
  },
  formLabel: {
    textAlign: "start",
    marginBottom: 10,
  },
};

export default ContactContentCard;
