import React from "react";
import ReefTankImage from "../assets/img/reef.png";
import ReefTankImage2 from "../assets/img/reefTank.png";
import TankContentCard from "./ContentCards/TankContentCard";
import description from "../constants/description.json";
const data = [
  {
    id: 1,
    images: [
      { img: ReefTankImage, caption: "Reef Tank" },
      { img: ReefTankImage2, caption: "Reef Tank" },
    ],
  },
];

const componentData = {
  title: "Reef",
  description: description.reef,
};
const Reef = () => {
  return <TankContentCard {...componentData} data={data} />;
};

export default Reef;
