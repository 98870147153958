import React from "react";
import TankContentCard from "./ContentCards/TankContentCard";
import description from "../constants/description.json";
import Discus from "../assets/img/discus.png";

const data = [
  {
    id: 1,
    images: [{ img: Discus, caption: "Discus" }],
  },
];

const componentData = {
  title: "Freshwater",
  description: description.freshwater,
};
const Freshwater = () => {
  return <TankContentCard {...componentData} data={data} />;
};

export default Freshwater;
