import React from "react";
import ContentCard from "./ContentCards/ContentCard";
import Home from "@material-ui/icons/Home";
import InvertColorsIcon from "@material-ui/icons/InvertColors";
import Build from "@material-ui/icons/Build";
import description from "../constants/description.json";
const data = [
  {
    id: 1,
    Icon: Home,
    iconColor: "primary",
    heading: "Consultation",
    descriptionHeading:
      "We provide consultation services for aquarium design, delivery, installation, and maintenance.",
  },
  {
    id: 2,
    Icon: InvertColorsIcon,
    iconColor: "secondary",
    heading: "Design",
    descriptionHeading:
      "We provide design services for new or existing aquariums. We will make sure your aquarium looks beautiful, elegant, and clean.",
  },
  {
    id: 3,
    Icon: Build,
    iconColor: "inherit",
    heading: "Delivery, Installation, & Maintenance",
    descriptionHeading:
      "We will take care of the entire aquarium process for you end to end! We will deliver your aquarium to your home and install it. We also offer year round aquarium maintenance.",
  },
];

const componentData = {
  title: "Servicing",
  description: description.servicing,
};
const Servicing = () => {
  return <ContentCard {...componentData} data={data} />;
};

export default Servicing;
