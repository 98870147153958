import "./App.css";
import Home from "./views/Home";
import Navbar from "./components/Navbar";
// import { Error } from "./views/Error";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Servicing from "./components/Servicing";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Reef from "./components/Reef";
import Saltwater from "./components/Saltwater";
import Freshwater from "./components/Freshwater";
import Cichlids from "./components/Cichlids";
import Goldfish from "./components/Goldfish";
import Nano from "./components/Nano";

function App() {
  return (
    <>
      <Router>
        {/* Navbar will always be on the page since 
      it is not inside of a route path*/}
        <Navbar />
        <div id="home"></div>
        <Switch>
          <Route path="/" component={Home} />
          {/* <Route path="*" component={Error} /> */}
        </Switch>
        <div className="content">
          <Reef />
          <div id="reef"></div>
          <Saltwater />
          <div id="saltwater"></div>
          <Freshwater />
          <div id="freshwater"></div>
          <Cichlids />
          <div id="cichlids"></div>
          <Goldfish />
          <div id="goldfish"></div>
          <Nano />
          <div id="nano"></div>
          <Servicing />
          <div id="servicing"></div>
          <Contact />
          <div id="contact"></div>
        </div>
        <Footer />
      </Router>
    </>
  );
}

export default App;
