import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Logo from "../assets/img/logo.png";
import CopyrightIcon from "@material-ui/icons/Copyright";
import { Link as ScrollLink } from "react-scroll";
// import * as devInfo from "../constants/developerInfo.json";
import * as info from "../constants/info.json";
const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    color: "white",
    typography: {
      fontFamily: `'Open Sans', 'Roboto'`,
    },
    marginTop: "10px",
    textAlign: "center",
  },
  footerIcons: {
    marginRight: 2,
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div style={{ background: "white" }}>
      <div style={styles.footer}>
        <div style={styles.textContainer}>
          <h2 style={styles.heading}>
            Let us add you to the list of our many happy clients!
          </h2>
          <h2 style={styles.heading}>Have any Questions?</h2>
          <ScrollLink
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            duration={1000}
            offset={0}
          >
            <Button
              size="large"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Contact Us Today
            </Button>
          </ScrollLink>
          <img src={Logo} alt={info.name} style={styles.footerLogo} />
        </div>
      </div>

      <div style={styles.footerInfoContainer}>
        <h5 style={styles.footerInfo}>
          <CopyrightIcon className={classes.footerIcons} fontSize="small" />
          {info.name} 2021
        </h5>
        {/* <h5 style={styles.developerInfo}>
          Developed by {devInfo.name}| {devInfo.email}
        </h5> */}
      </div>
    </div>
  );
};

const styles = {
  footer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "4vh",
    paddingRight: 20,
    paddingLeft: 20,
    border: "none",
    background: "white",
  },
  textContainer: {
    display: "flex",
    marginLeft: 10,
    textAlign: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  heading: {
    color: "black",
    marginBottom: 10,
  },
  footerLogo: {
    margin: "10px 0px",
    height: "170px",
  },
  footerInfoContainer: {
    color: "black",
    background: "white",
    textAlign: "center",
    // borderTop: "1px solid gray",
    padding: 10,
  },
  footerInfo: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 2,
    fontSize: 15,
  },
  developerInfo: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 2,
    fontSize: 10,
  },
};

export default Footer;
