import React from "react";
import Home from "@material-ui/icons/Home";
import NanoReef from "../assets/img/nanoreef.jpg";
import TankContentCard from "./ContentCards/TankContentCard";
import description from "../constants/description.json";
import ClownFish from "../assets/img/clownfish.png";

const data = [
  {
    id: 1,
    Icon: Home,
    images: [
      { img: NanoReef, caption: "Nano Reef" },
      { img: ClownFish, caption: "Clownfish" },
    ],
    iconColor: "primary",
    heading: "Sample 1",
    descriptionHeading: "Sample description here.",
    description: "More details regarding info here.",
  },
];

const componentData = {
  title: "Nano",
  description: description.nano,
};
const Nano = () => {
  return <TankContentCard {...componentData} data={data} />;
};

export default Nano;
