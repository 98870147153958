import React from "react";
import TankContentCard from "./ContentCards/TankContentCard";
import description from "../constants/description.json";
import GoldFish2 from "../assets/img/goldfish2.jpeg";

const data = [
  {
    id: 1,
    images: [{ img: GoldFish2, caption: "Gold Fish" }],
  },
];

const componentData = {
  title: "Goldfish",
  description: description.goldfish,
};
const Goldfish = () => {
  return <TankContentCard {...componentData} data={data} />;
};

export default Goldfish;
