import React from "react";
import ContactContentCard from "./ContentCards/ContactContentCard";
import ContactsIcon from "@material-ui/icons/Contacts";
import PhoneCallbackIcon from "@material-ui/icons/PhoneCallback";
import EmailIcon from "@material-ui/icons/Email";
import * as info from "../constants/info.json";

const contactData = {
  CardIcon: ContactsIcon,
  iconColor: "primary",
  heading: "Contact Information",
  PhoneIcon: PhoneCallbackIcon,
  phoneNumber: info.phoneNumber,
  EmailIcon: EmailIcon,
  email: info.email,
};

const componentData = {
  title: "Contact Us",
  description:
    "Please reach out if you need any assistance or have any inquiries. You may contact us with the information provided below, or by filling out the provided form below.",
};
const Contact = () => {
  return <ContactContentCard contactData={contactData} {...componentData} />;
};

export default Contact;
