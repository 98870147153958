import React from "react";
import Logo from "../assets/img/logo.png";
import * as info from "../constants/info.json";
import background from "../assets/img/background-2.jpeg";

const Home = () => {
  return (
    <>
      <div className="background">
        <img className="background-img" src={background} alt="Jelly Fish" />
        <div className="background-img-footer">
          <img className="display-pic" src={Logo} alt={info.name} />
          <div className="footer-conatiner-2">
            <div className="header">
              <h1>{info.name}</h1>
              <h4>
                {info.description} <br /> {info.phoneNumber}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
